/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
import clsx from "clsx";
import * as React from "react";

interface Props {
  label?: string;
  hint?: string;
  error?: string;
  actionComponent?: React.ReactNode;
  fullWidth?: boolean;
  prefixComponent?: React.ReactNode;
  inputRef?: React.RefObject<HTMLInputElement>;
}

const inputBase = "text-sm focus:outline-none w-full py-2 pr-10 pl-3 border border-gray-300 rounded-md disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200";
const inputError = "border-red-300";
const inputDisabled = "bg-gray-50 text-gray-500 border-gray-200";

const Input: React.FC<
  Props &
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >
> = ({
  label, required, hint, error, actionComponent, inputRef, prefixComponent, fullWidth = false, ...props
}) => (
  <div className={clsx(fullWidth && "w-full")}>
    <label {...(props.id ? { htmlFor: props.id } : {})}>
      <span className="block mb-1">
        {required && "*"}
        {label}
      </span>
      {hint && <span className="mb-2 block text-xs">{hint}</span>}
      <div className="flex gap-x-4 items-center">
        {prefixComponent && (
          <span>
            {prefixComponent}
          </span>
        )}
        <input
          {...props}
          ref={inputRef}
          required={required}
          className={clsx(
            inputBase,
            error && inputError,
            props.disabled && inputDisabled,
            props.className,
          )}
        />
        {actionComponent}
      </div>
      {error && <div className="text-red-500">{error}</div>}
    </label>
  </div>
);

export default Input;
