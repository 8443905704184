/* eslint-disable react/jsx-no-constructed-context-values */
import { useApolloClient, useLazyQuery } from "@apollo/client";
import { useAuth } from "@audacia-hq/shared/contexts";
import dayjs from "dayjs";
import minMax from "dayjs/plugin/minMax";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import {
  EXPERT_PICTURES_FRAGMENT,
  EXPERT_PROFILES_FRAGMENT,
  EXPERT_QUEUE_FRAGMENT,
  getExpertFragmentId,
} from "../graphql/fragments";
import { GET_EXPERT, GET_EXPERT_RELATIONS, GET_PENDING_ACTIONS } from "../graphql/query";
import {
  Expert, ExpertAccountStatus, ExpertPhone, PendingActions,
} from "../models/expert";
import { ExpertRelation, ExpertRelationList } from "../models/expertRelation";
import Loader from "../modules/common/components/Loader";

import { useWS, WSMessage } from "./WSContext";

dayjs.extend(minMax);
interface ExpertContextType {
  expert?: Expert;
  usedPhone?: string;
  pendingActions: PendingActions;
  /** Only used in the case of contracts, for now. Intended to stop activity status and planning manipulation. */
  hasBlockingActions: boolean;
  expertChannels: string[];
  expertStatus?: ExpertAccountStatus;
  refreshPendingActions: () => void;
  getRelations: (memberUids: string[]) => Promise<ExpertRelation[]>;
}

const defaultPendingActions: PendingActions = {
  writtenNbr: 0,
  privateMessagesNbr: 0,
  onGoingConsultation: null,
};

const ExpertContext = React.createContext<ExpertContextType>({
  pendingActions: defaultPendingActions,
  hasBlockingActions: false,
  refreshPendingActions: () => ({}),
  expertChannels: [],
  getRelations: () => Promise.resolve([]),
});

const ExpertProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user } = useAuth();
  const ws = useWS();

  const [getExpert, { data, loading, refetch: refreshExpert }] = useLazyQuery<{ expert: Expert, expertPhones: ExpertPhone[] }>(GET_EXPERT);
  const [getExpertRelation] = useLazyQuery<{ expertRelations: ExpertRelationList }>(GET_EXPERT_RELATIONS);
  const [getPendingActions, { data: pendingActions, refetch: refreshPendingActions }] = useLazyQuery<{ pendingActions: PendingActions }>(GET_PENDING_ACTIONS);
  const [relations, setRelations] = useState<ExpertRelation[]>([]);
  const [expertChannels, setExpertChannels] = useState<string[]>([]);
  const [expertStatus, setExpertStatus] = useState<ExpertAccountStatus>();
  const apolloClient = useApolloClient();
  const { t } = useTranslation("consultation");

  useEffect(() => {
    if (user) {
      getExpert();
    }
  }, [user]);

  const getExpertStatus = () => {
    const statusOrder = ["DISABLED", "RECRUITMENT", "TRIAL", "ACTIVATED"];
    if (data.expert.profiles.length === 0) return data.expert.status;
    return data.expert.profiles.reduce((acc, curr) => {
      if (acc === "") return curr.status;
      if (statusOrder.indexOf(curr.status) > statusOrder.indexOf(acc)) return curr.status;
      return acc;
    }, "");
  };

  const handlePictureAccepted = (validationUid, resource) => {
    apolloClient.cache.updateFragment({
      id: getExpertFragmentId(data.expert.expertUid),
      fragment: EXPERT_PICTURES_FRAGMENT,
    }, (({ pictures }) => ({
      pictures: [
        ...pictures,
        resource,
      ],
    })));
    apolloClient.cache.updateFragment({
      id: getExpertFragmentId(data.expert.expertUid),
      fragment: EXPERT_PROFILES_FRAGMENT,
    }, (({ profiles }) => ({
      profiles: profiles.map((profile) => ({
        ...profile,
        validations: profile.validations.filter((validation) => validation.validationUid !== validationUid),
      })),
    })));
  };

  const handleExpertStatusUpdatedEvent = (msg: WSMessage) => {
    const payload = JSON.parse(msg.payload);
    apolloClient.cache.updateFragment({
      id: getExpertFragmentId(data.expert.expertUid),
      fragment: EXPERT_PROFILES_FRAGMENT,
    }, (({ profiles }) => ({
      profiles: profiles.map((p) => ({
        ...p,
        presenceStatus: {
          ...p.presenceStatus,
          ...payload,
        },
      })),
    })
    ));
  };

  const handleWaitingRoomUpdatedEvent = (msg: WSMessage) => {
    const payload = JSON.parse(msg.payload);
    apolloClient.cache.updateFragment({
      id: getExpertFragmentId(data.expert.expertUid),
      fragment: EXPERT_QUEUE_FRAGMENT,
    }, (({ queue }) => ({
      queue: {
        ...queue,
        items: payload.items,
      },
    })));
  };

  const handleWaitingRoomToastEvent = (msg: WSMessage) => {
    const { toastType, ...fields } = JSON.parse(msg.payload);
    switch (toastType) {
      case "DROPPED#NO_CREDIT":
        toast.warning(t("queue.droppedNoCredit", { count: fields.count, nbItems: fields.nbItems }));
        break;
      case "DROPPED#BLACKLISTED":
        toast.warning(t("queue.droppedBlacklisted", { pseudo: fields.pseudo }));
        break;
      default: // do nothing;
    }
  };

  const handleExpertValidationAcceptedEvent = (msg: WSMessage) => {
    const { type, validationUid, resource } = JSON.parse(msg.payload);
    switch (type) {
      case "PICTURE":
        handlePictureAccepted(validationUid, resource);
        break;
      default:
      // do nothing
    }
  };

  const expertEventsHanlders = {
    "expert.ownStatus.updated": handleExpertStatusUpdatedEvent,
    "expert.ownWaitingRoom.updated": handleWaitingRoomUpdatedEvent,
    "expert.ownWaitingRoom.toast": handleWaitingRoomToastEvent,
    "expert.validation.accepted": handleExpertValidationAcceptedEvent,
  };

  const handleExpertEvents = (msg: WSMessage) => {
    if (msg.event === "ws.reconnected") {
      refreshExpert();
      refreshPendingActions();
      return;
    }
    if (msg.event.includes("expert.consultation") || msg.event.includes("expert.trialConsultation")) {
      refreshPendingActions();
    }
    const eventHandler = expertEventsHanlders[msg.event];
    if (!data?.expert || !eventHandler) return;
    eventHandler(msg);
  };

  useEffect(() => {
    if (!data || !data.expert) return;
    if (data.expert?.profiles[0]) {
      setExpertChannels([
        ...(data.expert.profiles[0].parameters.phoneEnabled ? ["PHONE"] : []),
        ...(data.expert.profiles[0].parameters.videoEnabled ? ["VIDEO"] : []),
        ...(data.expert.profiles[0].parameters.livechatEnabled ? ["LIVECHAT"] : []),
      ]);
    }

    getPendingActions({ variables: { trial: data.expert.status === "TRIAL" } });

    const sub = ws.subscribe((msg) => handleExpertEvents(msg as WSMessage));

    setExpertStatus(getExpertStatus() as ExpertAccountStatus);
    // eslint-disable-next-line consistent-return
    return () => sub.unsubscribe();
  }, [ws, data]);

  const getRelations = async (memberUids: string[]) => {
    const { existing, toFetch } = memberUids.reduce((acc, curr) => {
      if (!curr) return acc;
      const e = relations.find((r) => r.memberUid === curr);
      if (e) {
        return { ...acc, existing: [...acc.existing, e] };
      }
      return { ...acc, toFetch: [...acc.toFetch, curr] };
    }, { existing: [], toFetch: [] });
    if (toFetch.length > 0) {
      return getExpertRelation({ variables: { memberUids: toFetch } }).then((res) => {
        setRelations((prev) => [...prev, ...res.data.expertRelations.data]);
        return [...existing, ...res.data.expertRelations.data];
      });
    }
    return [...existing];
  };

  const realUsedPhone = data?.expertPhones.find((p) => p.usages.calls)?.phoneNumber;
  const usedPhone = realUsedPhone?.startsWith("client:") ? undefined : realUsedPhone;
  const hasBlockingActions = useMemo(() => {
    // May be extended in the future, only for blocking contracts for now.
    if (!pendingActions || !pendingActions.pendingActions) return false;
    if (pendingActions.pendingActions.contractSignature) {
      return pendingActions.pendingActions.contractSignature.some((c) => !c.approbationDeadline || dayjs(c.approbationDeadline).isBefore(dayjs()));
    }
    return false;
  }, [pendingActions]);

  return (
    <ExpertContext.Provider value={{
      expert: data?.expert,
      usedPhone,
      pendingActions: pendingActions?.pendingActions || defaultPendingActions,
      hasBlockingActions,
      expertChannels,
      expertStatus,
      refreshPendingActions,
      getRelations,
    }}
    >
      {loading ? <Loader /> : children}
    </ExpertContext.Provider>
  );
};

const useExpert = () => React.useContext(ExpertContext);

export default ExpertContext;
export { ExpertProvider, useExpert };
